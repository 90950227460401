<template>
  <div class="banner" :style="{ 'width': width, 'height': height }" v-if="list && list.length > 0">
    <el-carousel :height="height">
      <el-carousel-item v-for="(i, j) in list" :key="i.id">
        <img :style="{ 'width': width, 'height': height }" :src="i.imgUrl" />
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
export default {
  props: {
    width: {
      type: String,
    },
    height: {
      type: String,
    },
    list: {
      type: Array,
      default: []
    }
  },
  data() {
    return {};
  },
  created() {
  },
  methods: {
  },
};
</script>
<style lang="less" scoped>
.banner {
  margin: 0 auto;

  img:hover {
    cursor: pointer;
  }
}
</style>