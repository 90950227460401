<template>
  <div class="main">
    <div class="main-banner">
      <banner :width="'1000px'" :height="'575px'" :list="indexBanners"></banner>
    </div>

    <div class="main-zxxx">
      <div class="info-title main-zxxx-label">
        <img
          src="https://zhengyoulinart.obs.cn-east-3.myhuaweicloud.com/2023/01/04/445c354eb73b4e819d2fac0bdf1ca56f.png"
        />
      </div>
      <div class="main-zxxx-menus">
        <img
          class="info-jdzpflsx main-zxxx-img1"
          src="https://zhengyoulinart.obs.cn-east-3.myhuaweicloud.com/2023/01/04/ac6a86a35d2743c6bd5aeaf5a5595987.png"
          @click="goTo('ZUOPINXINSHANG')"
        />
        <img
          class="info-ysbj main-zxxx-img2"
          src="https://zhengyoulinart.obs.cn-east-3.myhuaweicloud.com/2023/01/04/30ded5f1c7304f6da0f291045cc245e4.png"
          @click="goTo('YISHUBIJI')"
        />
        <img
          class="info-zx main-zxxx-img3"
          src="https://zhengyoulinart.obs.cn-east-3.myhuaweicloud.com/2023/01/04/96e944f843eb4e2cb63a3bd7e7ae14b6.png"
          @click="goTo('ZIXUNXINXI')"
        />
        <img
          class="info-xxxz main-zxxx-img4"
          src="https://zhengyoulinart.obs.cn-east-3.myhuaweicloud.com/2023/01/04/6056831956f84d10b290893279362d86.png"
          @click="goTo('XINXINXUEZI')"
        />
      </div>
    </div>

    <div class="main-support" v-if="supportMenus && supportMenus.length > 0">
      <div class="support-content">
        <div class="support-content-label">
          <span>{{ supportLabel }}</span>
        </div>
        <div class="support-content-menus">
          <div
            class="support-content-menus-item"
            v-for="(i, j) in supportMenus"
            :key="i.id"
            :style="{ width: 1000 / supportMenus.length + 'px' }"
          >
            <div class="icon">
              <a :href="i.openUrl" target="_blank" v-if="i.openStyle == '1'">
                <img :src="i.imgUrl" />
              </a>
              <img v-else :src="i.imgUrl" @click="suportClickFn(i)" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Banner from "./components/Banner.vue";
import { get } from "../utils/request";
import { Message } from "element-ui";
export default {
  components: {
    Banner,
  },
  data() {
    return {
      supportLabel: "服务与支持",
      indexBanners: [],
      supportMenus: [],
    };
  },
  created() {
    this.getSupportMenusFn();
    this.getIndexBannersFn();
  },
  methods: {
    async getSupportMenusFn() {
      const { data: res } = await get("/public/indexSuport");
      if (res.code == 200) this.supportMenus = res.data;
    },
    async getIndexBannersFn() {
      const { data: res } = await get("/public/indexBanners");
      if (res.code == 200) this.indexBanners = res.data;
    },
    goTo(aliasTitle) {
      this.$router.push({
        path: "/articlesList",
        query: {
          aliasTitle: aliasTitle,
        },
      });
    },
    suportClickFn(menu) {
      if (!menu.openUrl)
        return Message({
          message: "敬请期待，功能开发中",
          type: "success",
          duration: 5 * 1000,
        });
      this.$router.push({
        path: menu.openUrl,
      });
    },
  },
};
</script>
<style lang="less" scoped>
.main {
  .main-banner {
    padding: 30px;
  }

  .main-zxxx {
    width: 1000px;
    margin: 0 auto;
    margin-bottom: 30px;

    .main-zxxx-label {
      margin-bottom: 20px;

      img {
        width: 180px;
      }
    }

    .main-zxxx-menus {
      position: relative;
      height: 420px;

      img {
        position: absolute;
        -webkit-object-fit: cover;
        object-fit: cover;
        transition: 0.5s all linear;
        -ms-transition: 0.5s all linear;
        -moz-transition: 0.5s all linear;
        -webkit-transition: 0.5s all linear;
        -o-transition: 0.5s all linear;
      }

      img:hover {
        cursor: pointer;
        transform: scale3d(1.1, 1.1, 1.1);
        -ms-transform: scale3d(1.1, 1.1, 1.1);
        -moz-transform: scale3d(1.1, 1.1, 1.1);
        -webkit-transform: scale3d(1.1, 1.1, 1.1);
        -o-transform: scale3d(1.1, 1.1, 1.1);
        z-index: 1000;
      }

      .main-zxxx-img1 {
        left: 0;
        top: 0;
        width: 450px;
        height: 420px;
      }

      .main-zxxx-img2 {
        top: 0;
        left: 458px;
        width: 365px;
        height: 246px;
      }

      .main-zxxx-img3 {
        right: 0;
        top: 0;
        width: 171px;
        height: 245px;
      }

      .main-zxxx-img4 {
        right: 0;
        bottom: 0;
        width: 543px;
        height: 166px;
      }
    }
  }

  .main-support {
    width: 100%;
    background-color: #0c0a0b;

    .support-content {
      width: 840px;
      height: 300px;
      margin: 0 auto;
      background: url(https://zhengyoulinart.obs.cn-east-3.myhuaweicloud.com/2023/01/04/1d48a9a58d1d41cd9541224fcbe54c4b.jpg)
        no-repeat center 50px #0c0a0b;
      background-size: auto 250px;

      .support-content-label {
        color: #fff;
        height: 100px;
        line-height: 100px;
        font-weight: 700;
        font-size: 25px;
      }

      .support-content-menus {
        display: flex;
        align-items: center;

        .support-content-menus-item {
          .icon {
            img {
              width: 130px;
            }

            img:hover {
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}
</style>